.header_top_lang_drop {
	display: none;
	position: absolute;
	right: 0;
	left: auto;
	top: 100%;
	z-index: 1;
	min-width: 240px;
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	margin-top: 10px;
	border: 1px solid #dadada;
}

.header_top_lang_drop a {
	position: relative;
	top: 5px;
	display: block;
	padding: 5px;
}
.header_top_lang_drop a span{
	vertical-align: top;
}
.header_top_lang_drop a span.name{
	display: inline-block;
	margin-left: 27px;
	vertical-align: top;
	color: #566370;
}
.header_top_lang_drop a + a {
	margin-top: 10px;
	border-top: 1px solid #f5f3f3;
	padding-top: 13px;
}
.header_top_lang_drop .flag-icon{
	margin-bottom: 0;
}
